<template>
  <div class="content-wrapper">
    <div class="work main-content">
      <div class="page-header">
        <h1>Add Work</h1>
        <WorkForm :viewMode="false" :eventName="'addWork'" @addWork="addWork"/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import dayjs from 'dayjs';
import WorkForm from '@/components/work/WorkForm.vue';
import Common from '@/lib/common';
import Hr from '@/lib/hr';
import Sales from '@/lib/sales';
import Work from '@/lib/work';

export default {
  name: 'AddWork',
  components: {
    WorkForm,
  },
  methods:{
    async addWork(form){
      try{
        const workInfo = Object.assign({}, form);
        const loginInfo = Common.getLoginInfo();

        const employee = await this.getEmployeeByName(form.principalEmployeeId);
        const employeeId = employee.id;
        workInfo.principalEmployeeId = employeeId;
        workInfo.deadlineStart = dayjs(form.deadline[0]).unix();
        workInfo.deadlineEnd = dayjs(form.deadline[1]).unix();

        const work = await Work.createWork(this.apiUrl, workInfo, loginInfo);
        this.$router.push('/work/overview');

      }catch(err){
        console.log(err);
      }
    },
    async getEmployeeByName(memberName){
      const loginInfo = Common.getLoginInfo();
      const searchFields = {
        'employee_name': memberName,
      };
      const employees = await Hr.searchEmployee(this.apiUrl, searchFields, loginInfo);
      const employee = employees[0];
      return employee;
    },
  },
  computed: mapState({
    apiUrl: state => state.apiUrl,
  }),
}
</script>

<style lang="scss">
.page-header{
  h1{
    margin-bottom: 50px;
  }
}
</style>
